@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		font-size: calc(100vw / 102.4);
	}
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.sp-view {
	display: none !important;
}

a {
	color: $textColor;
	&:hover {
		opacity: 0.7;
	}
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	margin: 0 0 20px;
	padding: 12px 0;
	border-bottom: 1px solid $borderColor;
	font-size: $xxs;
	line-height: 1.4;
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($baseColor, 0.9);
		color: $white;
		font-size: $xxxl;
		border-radius: 25px;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}