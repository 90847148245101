@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.ttl02 {
		color: $lightbrown;
		font-size: 2.5rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 20px;
		&::before,
		&::after {
			content: "\f067";
			font-family: 'icomoon';
			font-size: 1.8rem;
		}
		&::before {
			margin-right: 20px;
		}
		&::after {
			margin-left: 20px;
		}
	}
	/* visual
	----------------------------------------------------------- */
	.visual {
		position: relative;
		max-width: 100%;
		margin: 0 auto;
		
		.slick-slide {
			position: relative;
			margin: 0 17px;
			
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				background: rgba(#000, 0.15);
			}
			&.slick-current::before {
				display: none;
			}
		}
		.slick-slide:not(.slick-center) {
			filter: opacity(70%);
			transition: 0.2s linear;
		}
		.slick-dots {
			bottom:0 ;
			button {
				&:before {
					color: $white;
				}
			}
		}
		
		.slick-prev,
		.slick-next {
			width: 16px;
			height: 46px;
			
			&::before {
				content: '';
			}
		}
		button.slick-prev,
		button.slick-next {
			z-index:2;
		}
		.slick-prev{ left: 22%; }
		.slick-next{ right: 22%; }
		.slick-arrow{ z-index: 10; }		
	}
	
	/* index-news
	----------------------------------------------------------- */
	.index-news {
		background:url(/common/img/index/bg_index_01.png) right no-repeat;
		background-size:cover;
		&__title {
			color: $lightbrown;
			font-weight: bold;
			line-height: 55px;
			background: $baseColor;
			font-size: 1.8rem;
			padding-left: 20px;
			flex: 1 1 auto;
			text-align: center;
			margin-bottom: 20px;
		}
		.index-bnr {
			&__item {
				border: 1px solid #cacaca;
				color: $darkbrown;
				font-size: 1.8rem;
				font-weight: bold;
				position: relative;
				padding: 25px;
				margin-top: 15px;
				display: block;
				
				.icon-chevron-right{
					right: 15px;
					@include centering-elements(false, true);
				}
			}
		}
		.index-bnr__item--seminar {
			background: url(/common/img/index/bnr_inxex_01.png) no-repeat;
			background-size: cover;
		}
		.index-bnr__item--newspaper {
			background: url(/common/img/index/bnr_inxex_02.png) no-repeat;
			background-size: cover;
		}
	}
	/* index-subject
	----------------------------------------------------------- */
	.index-subject {
		background:url(/common/img/index/bg_index_02.png) repeat;
		&__item {
			background: rgba(255,255,255,0.8);
			padding: 10px;
			text-align: center;
			&__img {
				padding: 40px 80px 30px;
				@media screen and (min-width:  767.5px) and (max-width:1024px) { padding: 40px 40px 30px; }
				
				&__text {
					background: rgba(255,255,255,0.8);
					padding: 0px 0px 30px;
					&__icon {
						position: relative;
						margin-top: -25px;
						width: 82px;
						opacity: 0.8;
					}
					.btn {
						border-radius: 30px;
						height: 35px;
						min-width: 155px;
						margin-top: 20px;
					}
				}
			}
			
			&:first-child {
				.index-subject__item__img {
					background: url(/common/img/index/pic_index_01.png) center center no-repeat;
					background-size: cover;
				}
			}
			&:last-child {
				.index-subject__item__img {
					background: url(/common/img/index/pic_index_02.png) center center no-repeat;
					background-size: cover;
				}
			}
		}
	}
	/* index-time
	----------------------------------------------------------- */
	.index-time {
		.table {
			tbody {
				th,td {
					padding: 5px;
					height: 45px;
					line-height: 120%;
				}
				th {
					width: 28%;
				}
				td {
					width: 12%;
				}
			}
		}
		&__tel {
			background: $yellow;
			color: $lightbrown;
			padding: 10px 40px;
			font-size: 1.6rem;
			vertical-align: middle;
			font-weight: bold;
		}
	}
	/* index-beauty
	----------------------------------------------------------- */
	.index-beauty {
		background: url(/common/img/index/bg_index_04.png) no-repeat center top,url(/common/img/index/bg_index_03.png) repeat-x;
		margin-bottom: 50px;
		.ttl02 {
			&:before,
			&:after {
				color: #72add9;
			}
		}
		&__item {
			background: rgba(36,123,190,0.6);
			padding: 2px;
			margin: 40px 45px 0;
			width: 160px;
			height: 160px;
			border-radius: 80px;
			
			&:first-child {
				margin: 40px 45px 0 0;
			}
			&:last-child {
				margin: 40px 0 0 45px;
			}
			
			&__link {
				border: 1px solid #a9cde6;
				border-radius: 78px;
				width: 156px;
				height: 156px;
				color: $white;
				display: block;
				position: relative;
				padding: 10px;
				font-size: 1.6rem;
				line-height: 130%;
				font-weight: bold;
				text-align: center;
				
				&__img {
					width: 40%;
					margin-right: 12px;
					display: block;
					margin: 10px auto;
				}
				.icon-chevron-right {
					font-size: 1.8rem;
					display: block;
					bottom: 15px;
					@include centering-elements(true, false);
				}
			}
		}
	}
	/* loopSlide
	----------------------------------------------------------- */
	#loopSlide {
		.simply-scroll-container {
			position: relative;
		}
		.simply-scroll-clip {
			position: relative;
			overflow: hidden;
		}
		.simply-scroll-list {
			overflow: hidden;
			margin: 0;
			padding: 0;
		}
		.simply-scroll-list li {
			float: left; /* Horizontal scroll only */
			padding: 0;
			margin: 0 10px 0 0;
			width: 380px;
			height: 265px;
		}
		.simply-scroll-list li img {
			display: block;
			width: 100%;
		}
	}
	/* index-company
	----------------------------------------------------------- */
	.index-company {
		&__tel {
			font-size: 2.7rem;
			color: $lightbrown;
			font-weight: bold;
			display: flex;
			align-items: center;
			line-height: 120%;
			margin-bottom: 10px;

			.icon-phone {
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $baseColor;
				color: #fff;
				width: 26px;
				height: 26px;
				border-radius: 13px;
				font-size: 2.2rem;
			}
		}
		
	}
}