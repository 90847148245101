@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.ttl02 {
		color: $lightbrown;
		font-size: 2.2rem;
		font-weight: bold;
		text-align: center;
		margin-bottom: 20px;
		&::before,
		&::after {
			content: "\f067";
			font-family: 'icomoon';
			font-size: 1.8rem;
		}
		&::before {
			margin-right: 20px;
		}
		&::after {
			margin-left: 20px;
		}
	}
	
	/* visual
	----------------------------------------------------------- */
	.visual {
		position: relative;
		max-width: 100%;
		margin: 0 auto;
		
		.slick-slide {
			position: relative;
			margin: 0;
			
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				background: rgba(#000, 0.15);
			}
			&.slick-current::before {
				display: none;
			}
		}
		.slick-dots {
			bottom:0 ;
			button {
				&:before {
					color: $white;
				}
			}
		}
	}
/* index-news
	----------------------------------------------------------- */
	.index-news {
		background:url(/common/img/index/bg_index_sp_01.png) right bottom no-repeat;
		background-size: contain;
		&__title {
			font-weight: bold;
			line-height: 45px;
			color: $lightbrown;
			background: $baseColor;
			font-size: 1.6rem;
			padding-left: 15px;
			flex: 1 1 auto;
			text-align: center;
		}
		.index-bnr {
			
			&__item {
				border: 1px solid #cacaca;
				color: $darkbrown;
				font-size: 1.8rem;
				font-weight: bold;
				position: relative;
				padding: 20px 5px;
				text-align: center;
				display: block;
				margin-bottom: 10px;
				
				.icon-chevron-right{
					right: 15px;
					@include centering-elements(false, true);
				}
			}
		}
		.index-bnr__item--seminar {
			background: url(/common/img/index/bnr_inxex_01.png) no-repeat;
			background-size: cover;
		}
		.index-bnr__item--newspaper {
			background: url(/common/img/index/bnr_inxex_02.png) no-repeat;
			background-size: cover;
		}
	}
	/* index-subject
	----------------------------------------------------------- */
	.index-subject {
		background:url(/common/img/index/bg_index_02.png) repeat;
		&__item {
			background: rgba(255,255,255,0.8);
			padding: 10px;
			text-align: center;
			&__img {
				padding: 35px 30px 25px;
				&__text {
					background: rgba(255,255,255,0.8);
					padding: 0px 10px 30px;
					&__icon {
						position: relative;
						margin-top: -25px;
						width: 82px;
						opacity: 0.8;
					}
					.btn {
						border-radius: 30px;
						height: 35px;
						min-width: 155px;
						margin-top: 15px;
					}
				}
			}
			
			&:first-child {
				.index-subject__item__img {
					background: url(/common/img/index/pic_index_01.png) center center no-repeat;
					background-size: cover;
				}
			}
			&:last-child {
				.index-subject__item__img {
					background: url(/common/img/index/pic_index_02.png) center center no-repeat;
					background-size: cover;
				}
			}
		}
	}
	/* index-time
	----------------------------------------------------------- */
	.index-time {
		.table {
			th,td {
				padding: 5px 2px;
				height: 45px;
				line-height: 120%;
				font-size: 1.2rem;
			}
			th {
				width: 28%;
			}
			td {
				width: 12%;
			}
		}
		&__tel {
			display: block;
			background: $yellow;
			color: $lightbrown;
			padding: 5px 0px;
			width: 100%;
			font-size: 1.6rem;
			vertical-align: middle;
			font-weight: bold;
		}
	}
	/* index-beauty
	----------------------------------------------------------- */
	.index-beauty {
		background: url(/common/img/index/bg_index_sp_04.png) no-repeat center top,url(/common/img/index/bg_index_03.png) repeat-x;
		background-size: contain;
		.ttl02 {
			&:before,
			&:after {
				color: #72add9;
			}
		}
		&__item {
			background: #72add9;
			padding: 2px;
			margin-bottom: 10px;
			&__link {
				border: 1px solid #a9cde6;
				color: $white;
				display: flex;
				align-items: center;
				position: relative;
				padding: 10px;
				font-size: 1.5rem;
				font-weight: bold;
				&__img {
					width: 15%;
					margin-right: 12px;
				}
				.icon-chevron-right {
					right: 10px;
					font-size: $xxxs;
					@include centering-elements(false, true);
				}
			}
		}
	}
	/* loopSlide
	----------------------------------------------------------- */
	#loopSlide {
		.simply-scroll-container {
			position: relative;
		}
		.simply-scroll-clip {
			position: relative;
			overflow: hidden;
		}
		.simply-scroll-list {
			overflow: hidden;
			margin: 0;
			padding: 0;
		}
		.simply-scroll-list li {
			float: left; /* Horizontal scroll only */
			padding: 0;
			margin: 0 10px 0 0;
			width: 250px;
			height: 174px;
		}
		.simply-scroll-list li img {
			display: block;
			width: 100%;
		}
	}
	/* index-company
	----------------------------------------------------------- */
	.index-company {
		&__item {
			border: 2px solid $baseColor;
			border-radius: 10px;
			padding: 5px 20px;
			margin-bottom: 15px;
			text-align: center;
		}
		&__tel {
			font-size: 2.7rem;
			color: $lightbrown;
			font-weight: bold;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 120%;

			.icon-phone {
				margin-right: 5px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: $baseColor;
				color: #fff;
				width: 26px;
				height: 26px;
				border-radius: 13px;
				font-size: 2.2rem;
			}
			@media screen and (min-width:  430px) and (max-width:767.499px) {
				.icon-phone {
					font-size: 1.2rem;
				}
			}
		}
		.btn {
			border-radius: 10px;
		}
	}
}