@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1040px;
		height: 110px;
		margin: 0 auto;
		padding: 0 20px;
	}
	
	
	.header-logo {
		flex: 0 1 350px;
	}
	
	.heder-contact {
		text-align: center;
		font-weight: bold;
		color: $lightbrown;
	
		&__item {
			margin: 0 5px;
			&__category {
				background: $baseColor;
				border-radius: 3px;
				padding: 2px 0px;
				min-width: 170px;
				font-size: 1.3rem;
			}
			&__tel {
				font-size: 2.1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				color: $lightbrown;
				
				.icon-phone {
					margin-right: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: $lightbrown;
					color: $white;
					width: 16px;
					height: 16px;
					border-radius: 8px;
					font-size: 1.2rem;
				}
			}
		}
	}

	.gnav {
		display: flex;
		max-width: 1000px;
		margin: 0 auto;
		
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0;
			margin-bottom: 25px;
			font-size: 1.6rem;
			color: $lightbrown;
			line-height: 100%;
			border-left: 1px solid #c5b097;
			width: calc(100% / 6 );
			font-weight: bold;
			
			&:last-child {
				border-right: 1px solid #c5b097;
			}
		}
	}
	
	
}


