@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	.inner {
		max-width: 1040px;
		padding: 60px 20px;
		margin: 0 auto;
	}
}
.bg--yellow {
	background: #fffcf5;
}

/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}

a[href^="tel:"] {
	cursor: default;
	&:hover {
		opacity: 1.0;
	}
}

/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
	background: url(/common/img/common/bg_ttl01.png) center center no-repeat;
	background-size: cover;
	font-size: 3.6rem;
	font-weight: bold;
	line-height: 1.2;
	color: $lightbrown;
	font-family: "Midashi Go MB31";
	text-shadow: 0px 0px 3px $white;
	letter-spacing: 3px;
	
	&__text {
		width: 1000px;
		margin: 0 auto;
	}
}

.ttl02 {
	color: $lightbrown;
	font-size: 2.5rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 20px;
	
	&__icon {
		display: block;
		color: $baseColor;
		font-size: 1.2rem;
		&::before,
		&::after {
			content: "\f067";
			font-family: 'icomoon';
			color: $lightblue;
		}
		&::before {
			margin-right: 10px;
		}
		&::after {
			margin-left: 10px;
		}
	}
}

.ttl03 {
	color: $lightbrown;
	font-size: 2.2rem;
	font-weight: bold;
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	padding-bottom: 15px;
	
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px solid $lightblue;
		width: 40px;
		margin: 0 auto;
	}
}

.ttl04 {
	color: $lightbrown;
	font-size: 1.8rem;
	font-weight: bold;
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	padding-bottom: 15px;
	
	&::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 1px solid $lightblue;
		width: 40px;
		margin: 0 auto;
	}
}

.ttl05 {
	margin: 0 0 20px;
	padding: 0 0 10px;
	border-bottom: 1px solid $lightblue;
	color: $lightbrown;
	font-size: 1.8rem;
	font-weight: bold;
	line-height: 1.4;
	display: flex;
	align-items: center;
	
	&::before {
		content: "\f067";
		font-family: 'icomoon';
		color: $lightblue;
	}
	&::before {
		margin-right: 10px;
	}
}

.ttl06 {
	margin: 0 0 15px;
	color: $lightblue;
	font-size: 1.6rem;
	font-weight: bold;
	line-height: 1.4;
}


/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -15px;
	 margin-right: -15px;

	 &> * {
		 margin-left: 15px;
		 margin-right: 15px;
		 width: calc((100% - 61px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}

/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}


/* 画像回り込みカラム
------------------------------------------------------------ */
.float {
	&::after {
		display: block;
		content: "";
		clear: both;
	}

	&--left {
		.float__item {
			float: left;
			margin: 0 20px 20px 0;
			max-width: 50%;

			* {
				width: 100%;
			}
		}
	}

	&--right {
		.float__item {
			float: right;
			margin: 0 0 20px 20px;
			max-width: 50%;

			* {
				width: 100%;
			}

		}
	}
}


/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--yellow {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}
	
	&--brown {
		& > * {
			&::before {
				background: $lightbrown;
			}
		}
	}
	
	&--blue {
		& > * {
			&::before {
				background: $lightblue;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;
	background: $white;

	&--brown {
		background: #f9f1e4;
	}

	&--border-blue {
		border: 1px solid $lightblue;
	}
	
	&--red {
		background: #fde9e9;
		border: 1px solid #f8cfd4;
	}
}


/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		background: $white;
		border: 1px solid $lightbrown;
		font-size: $s;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-brown {
		background: $lightbrown;
		color: $white;
	}
	.bg-beige {
		background: #f9f1e4;
	}
	.border-white {
		border-right: 1px solid $white!important;
	}
	.error {
		background: #fde9e9;
		color: #dd0000;
	}	
}

.table--center {
	th,
	td {
		text-align: center;
	}
}

.table--thin {
	th,
	td {
		padding: 5px 10px;
		line-height: 1.4;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	height: 50px;
	margin: 0 5px;
	padding: 0 30px;
	color: $white;
	font-size: $m;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	transition: 0.3s all;
	@include radius(5);

	.icon-chevron-right,
	.icon-chevron-down {
		right: 15px;
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	
	.icon-chevron-left,
	.icon-chevron-top {
		left: 15px;
		font-size: $xxxs;
		@include centering-elements(false, true);
	}
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--brown {
		background: $brown;
	}
	&--yellow {
		background: $baseColor;
	}
	&--blue {
		background: $lightblue;
	}
	&--border-blue {
		border: 1px solid $lightblue;
		color: $lightblue;
		font-weight: bold;
		background: none;
	}
	&--lg {
		min-width: 220px;
		font-size: $m;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}

button,
input[type="button"].btn,
input[type="submit"].btn {
	appearance: none;
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;
		& > * {
			display: block;
			background: $lightblue;
			color: $white;

			&.current,
			&:hover {
				background: $baseColor;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}
.pager--number {
	li {
		& > * {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.4rem;
			font-weight: bold;
			width: 44px;
			height: 44px;
			border-radius: 22px;
		}
	}
}
.pager--text {
	li {
		& > * {
			border-radius: 3px;
			padding: 10px 15px;
		}
	}
}

/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	background: #72add9;
	cursor: pointer;
	color: $white;
	line-height: 1.6;
	
	&__icon {
		right: 10px;
		color: $white;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding: 15px 30px 20px;
	background: $white;
	border-bottom: 1px solid #e0c7af;
}


/* --------------------------------------------------- */
/* form */
/* --------------------------------------------------- */
.label-must {
	right: 10px;
	padding: 2px 10px;
	background: $lightbrown;
	color: $white;
	font-size: $xxs;
	@include centering-elements(false, true);
}

.error-block {
	margin-top: 5px;
	font-weight: bold;
}

input[type="text"] {
	appearance: none;
	height: 40px;
	padding: 0 10px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	font-size: $m;
	@include radius(0);
}

textarea {
	appearance: none;
	padding: 5px 10px;
	width: 100%;
	height: 200px;
	border: 1px solid #d2d2d2;
	color: $textColor;
	@include radius(0);
}

select {
	height: 40px;
	padding: 0 0 0 10px;
	background: $white;
	border: 1px solid #d2d2d2;
}


/* radio
----------------------------------------------------------- */
.radio {
	cursor: pointer;
	
	input[type="radio"] {
		display: none;
	}
	input[type="radio"] + span {
		position: relative;
		display: block;
		padding: 0 0 0 25px;
		color: $textColor;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			content: '';
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
			@include circle(14);
		}
		&::after {
			left: 5px;
			background: $lightbrown;
			@include circle(8);
			opacity: 0;
		}
	}

	input[type="radio"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}


/* checkbox
----------------------------------------------------------- */
.checkbox {
	input[type="checkbox"] {
		display: none;
	}

	input[type="checkbox"] + span {
		position: relative;
		padding: 0 0 0 22px;
		color: $textColor;
		cursor: pointer;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::before {
			left: 0;
			content: '';
			width: 12px;
			height: 12px;
			background: #fff;
			border: 2px solid darken($borderColor, 5%);
		}
		&::after {
			left: 1px;
			content: "\f00c";
			color: $lightbrown;
			font-family: 'icomoon';
			font-size: $m;
			opacity: 0;
		}
	}
	input[type="checkbox"]:checked + span {
		&::after {
			opacity: 1;
		}
	}
}



/* --------------------------------------------------- */
/* label */
/* --------------------------------------------------- */
.label {
	width: 80px;
	height: 25px;
	background: $baseColor;
	color: #fff;
	font-size: $xxs;
	text-align: center;
	line-height: 25px;
}


/* --------------------------------------------------- */
/* news-list */
/* --------------------------------------------------- */
.news-list {
	margin: 0px 0 40px;
	
	&__item {
		padding: 15px 0;
		
		&__title {
			display: flex;
			align-items: center;
			border-bottom: 1px solid $baseColor;
			font-size: 1.6rem;
			font-weight: bold;
			margin-bottom: 5px;
		}
		
		&__date {
			flex: 0 0 auto;
			margin: 0 20px 0 0;
			color: $baseColor;
		}
		&__link {
			flex: 1 1 auto;
			color: $lightbrown;
		}
	}
}


/* --------------------------------------------------- */
/* entry */
/* --------------------------------------------------- */
.entry {
	&__ttl {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 0 40px;
	}
	&__block {
		padding: 0 0 50px;
		border-bottom: 1px solid $borderColor;
	}
}
