@charset "utf-8";

$baseColor: #f4c94e;
$textColor: #1c1816;
$borderColor: #e8e8e8;

$white: #fff;
$lightbrown: #886454;
$brown: #725347;
$darkbrown: #534741;
$yellow: #fce890;
$lightblue :#72add9;

@mixin clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin radius($size) {
	border-radius: ($size + px);
}

@mixin circle($size){
	width: $size + px;
	height: $size + px;
	border-radius: 50%;
}

@mixin transition {
	transition: 0.3s all;
}

@mixin opacity($opacity: 0.7) {
	opacity: $opacity;
}

@mixin centering-elements($horizontal: true, $vertical: true) {
	position: absolute;
	@if $horizontal and $vertical {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	} @else if $horizontal {
		left: 50%;
		transform: translate(-50%, 0);
	} @else if $vertical {
		top: 50%;
		transform: translate(0, -50%);
	}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body {
	font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;
	font-feature-settings : "palt";
	letter-spacing: normal;
}
@media all and (-ms-high-contrast:none) {
	body {
		font-family: "メイリオ",Meiryo,"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;;
	}
}
@-moz-document url-prefix() {
	@font-face {
	  font-family: "YuGothic M";
	  src: local("Yu Gothic Medium");
	}
	
	body {
	  font-family: "游ゴシック体", YuGothic, "YuGothic M", sans-serif;
	}
}


/* font-size
-------------------------------------------------- */
$xxxs: 1.0rem;
$xxs: 1.2rem;
$xs: 1.3rem;
$s: 1.4rem;
$m: 1.6rem;
$l: 1.8rem;
$xl: 2.0rem;
$xxl: 2.2rem;
$xxxl: 2.4rem;

