@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	display: flex;
	
	.inner {
		height: 50px;
		padding: 0 15px;
		flex: 1 1 auto;
		align-items: center;
	}
	
	.header-logo {
		display: flex;
		width: 200px;
		height: 50px;
		align-items: center;
		img {
			vertical-align: middle;
		}
	}
	.heder-contact {
		width: 50px;
		height: 50px;
		position: absolute;
		font-size: 2.4rem;
		text-align: center;
		right: 50px;
		top: 0;
		background: $lightbrown;
		line-height: 100%;
		padding: 2px 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		a {
			color: $white;	
		}
	}
	@media screen and (min-width:  500px) and (max-width:767.499px) {
		.heder-contact {
			font-size: 1.6rem;
		}
	}

	.header-menu,
	.header-menu span {
		@include transition;
	}

	.header-menu {
		position: relative;
		width: 50px;
		height: 50px;
		z-index: 10;
		cursor: pointer;
		background: $baseColor;

		&__item {
			display: inline-block;
			position: absolute;
			left: 20%;
			width: 60%;
			height: 3px;
			background: $white;
			@include radius(2);

			&:nth-of-type(1) { top: 15px; }
			&:nth-of-type(2) { top: 23px; }
			&:nth-of-type(3) { top: 31px; }
		}
		&.active {
			background: rgba(0,0,0,0.40);
			.header-menu__item {
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); top:15px; }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); top:35px; } 
			}
		}
	}

	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 80px 15px 15px;
		background: rgba(#000, 0.85);
		opacity: 0;
		@include transition;

		&__item {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: $baseColor;
			}
		}
		
		.show & {
			z-index: 1;
			opacity: 1;
		}
	}
}

