@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	background: #99c4e1;
	line-height: 1.6;
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 100%;
		margin: 0 auto;
		padding: 0 20px;

		.footer-logo {
			padding: 20px 0;
			
			&__link {
				display: block;
				width: 85%;
				margin: 0 auto 15px;
			}
			&__address {
				color: #1c1816;
			}
		}
		.footer-credit {
			margin-bottom: 30px;
			text-align: center;
			&__img {
				width: 100%;
			}
		}
	}
}
.copyright {
	padding: 15px 0;
	background: #4d4d4d;
	color: $white;
	text-align: center;
	font-size: $xxs;
}